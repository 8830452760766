
import MainTable from "@/modules/inventoryEntries/components/MainTable.vue";
import SearchForm from "@/modules/inventoryEntries/components/SearchForm.vue";
export default {
    components: {SearchForm, MainTable},
    setup() {
        const tableName = 'inventoryEntries'
        return {
            tableName
        }
    }
}
